import React, { useState, useEffect } from "react";

import AdEngageLayout from "components/layout";
import { adEngagePath, dataLoadingPath, CURRENT_TOP_EVENT_KEY, TOP_EVENTS } from "utils/constants";

import classNames from "classnames";
import * as styles from "components/category_overview/styles.module.scss";
import  * as colors from "styles/colors.module.scss";
import AdEngageButton from "components/ad_engage_button";
import {Link} from "gatsby";
import TopEventByCategoryOverview from "components/category_overview/TopEventByCategoryOverview";


const TopNflAds3PublicAdEngage = () => {
  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': 'DC-12429278/visit/nflpa0+unique'
      });
    }
  });

  const pageHeader = () => (
    <div className={classNames(styles.pageHeader)}>
      <div className={classNames(styles.headerBanner)}>
        <div className={classNames(styles.headerTitle)}>
          <h2 className={classNames(colors.white, styles.headerBold, "edo-h2")}>Who's really winning the NFL — including the Super Bowl?</h2>
        </div>
        <div className={classNames(styles.headerTitle)}>
          <h4 className={classNames(colors.white, styles.headerBold, "edo-h4")}>See which ads move the <del>ball</del> business — hours after the Big Game.</h4>
        </div>
        <AdEngageButton label="Request Demo" gotoLink="/contact-us" href={"/contact-us"}
                        extraClassNames={classNames(styles.headerButton, styles.demoButton)}/>
        <span className={classNames(styles.headerButton, colors.white)}>
          <a href="https://landing.edo.com/super-bowl-lvii" className={styles.viewFeatures}>Sign Up For Updates</a>
        </span>
      </div>
    </div>
  );

  const pageFooter = () => (
    <div className={classNames(styles.pageFooter)}>
      <div className={"flex-col"}>
        <div className={classNames(styles.footerText, styles.nflFooter)}>
          When your ad is on the line, access to real-time customer engagement is the only thing that counts. <b>Contact one of our industry specialists to learn more.</b>
        </div>
        <div className={styles.nflDemoButton}>
          <AdEngageButton label="Reach Out" gotoLink="/contact-us" href={"/contact-us"}
                          extraClassNames={classNames(styles.headerButton, styles.demoButton)}/>
        </div>
      </div>
    </div>
  );

  return (
    <AdEngageLayout pageTitle={TOP_EVENTS.nfl.title} >
      <TopEventByCategoryOverview
        pageHeader={pageHeader()}
        pageFooter={pageFooter()}
      />
    </AdEngageLayout>
  )
};

export default TopNflAds3PublicAdEngage;
